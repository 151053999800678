import * as React from "react";
import { observer } from "mobx-react";
import { UitkTertiaryButton } from "uitk-react-button";
import { UitkSpacing } from "uitk-react-spacing";
import { useLocalization } from "@shared-ui/localization-context";
import { ToggleContainerProps, ToggleContainerFlexModuleModelResult } from "../typings";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { UitkHeading } from "uitk-react-text";
import { RegionChildrenWithGridContainer } from "src/utils/RegionUtils";

export enum ToggleContainerState {
  ALL_EXPANDED = "expand",
  ALL_COLLAPSED = "collapse",
  NEITHER = "neither",
}

export interface ToggleAllContainerContext {
  collapseAll?: string;
  toggleCollapseAll?: any;
}

const defaultContext = {
  collapseAll: ToggleContainerState.NEITHER,
};
const expandButton = "simpleContainer.expandAllButton";
const collapseButton = "simpleContainer.collapseAllButton";

export const ToggleAllContainerContext = React.createContext<ToggleAllContainerContext>(defaultContext);

export const ToggleAllContainer = observer(
  ({ blossomComponent, templateComponent, flexModuleModelStore }: ToggleContainerProps) => {
    const { formatText } = useLocalization();
    const [collapseAll, setCollapseAll] = React.useState(ToggleContainerState.NEITHER);

    const model = flexModuleModelStore.getModel(
      templateComponent.metadata.id
    ) as ToggleContainerFlexModuleModelResult | null;

    if (!model || !blossomComponent || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const { title, innerTitle } = model;

    const toggleCollapseAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { value } = e.currentTarget;

      if (
        value === ToggleContainerState.NEITHER ||
        value === ToggleContainerState.ALL_COLLAPSED ||
        value === ToggleContainerState.ALL_EXPANDED
      ) {
        setCollapseAll(value);
      } else {
        setCollapseAll(ToggleContainerState.NEITHER);
      }
    };

    return (
      <ToggleAllContainerContext.Provider value={{ collapseAll, toggleCollapseAll }}>
        <UitkSpacing padding={{ inline: "three" }}>
          <div>
            {title && (
              <UitkHeading tag="h2" size={3}>
                {title}
              </UitkHeading>
            )}
            {innerTitle && (
              <UitkHeading tag="h2" size={3}>
                {innerTitle}
              </UitkHeading>
            )}
            <UitkSpacing margin={{ block: "three" }}>
              <div data-testid={collapseAll} id="expand-collapse-buttons">
                <UitkTertiaryButton onClick={toggleCollapseAll} value={ToggleContainerState.ALL_EXPANDED}>
                  {formatText(expandButton)}
                </UitkTertiaryButton>
                <UitkTertiaryButton onClick={toggleCollapseAll} value={ToggleContainerState.ALL_COLLAPSED}>
                  {formatText(collapseButton)}
                </UitkTertiaryButton>
              </div>
            </UitkSpacing>
          </div>
        </UitkSpacing>
        <RegionChildrenWithGridContainer templateComponent={templateComponent} blossomComponent={blossomComponent} />
      </ToggleAllContainerContext.Provider>
    );
  }
);
